<template>
  <div>
    <CNavbar light color="light" class="px-0">
      <h4>Teacher Class Mapping</h4>
      <CForm inline>
        <CInput class="mr-0 mb-0" placeholder="Search" size="sm" />
        <CButton color="dark" size="sm" class="border-0">
          <CIcon name="cil-search" size="sm" />
        </CButton>
      </CForm>
      <CForm inline>
        <CButton
          color="outline-dark "
          class="my-2 my-sm-0 btn-out-dashed"
          type="submit"
          size="sm"
          @click="teacher_map"
          >+ Add New</CButton
        >
      </CForm>
    </CNavbar>

    <div class="table-wrapper py-3">
      <CRow>
        <CCol col="12" xl="12">
          <CDataTable
            class="teacher-mapping-table"
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="5"
            :active-page="activePage"
            :pagination="{
              doubleArrows: false,
              align: 'left',
            }"
            @page-change="pageChange"
          >
          </CDataTable>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
//import teachersData from "./TeachersData";
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;

export default {
  name: "TeacherMappingData",
  data() {
    return {
      items: [],
      fields: [
        {
          key: "techaer_name",
          label: "Teacher Name",
          _classes: "font-weight-bold",
        },
        { key: "class_standard",label: "Class" },
        { key: "subject",label: "Subject" },
        { key: "division",label: "Division" },
        { key: "ac_year",label: "Year" },
      ],
      activePage: 1,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    teacher_map(){
       this.$router.push({ name: "AddTeacherMap"});
    },
    get_mapped_data(){
       var promise = apis.list_TeacherSubjectMappings().then( response => {
          this.items = response.data;
          console.log(this.items);
         
      }).catch( errror => {

      })
    },
    // rowClicked(item, index) {
    //   this.$router.push({ path: `users/${index + 1}` });
    // },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
  created(){
    this.get_mapped_data();
  }
};
</script>

<style></style>
