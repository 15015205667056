<template>
  <div>
    <CRow>
      <CCol lg="7">
        <TeacherMappingTable />
      </CCol>
      <CCol lg="5">
        <AddTeacher />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TeacherMappingTable from "./TeacherMappingTable";
import AddTeacher from "./AddTeacher";
export default {
  name: "Teacher",
  components: { TeacherMappingTable, AddTeacher },
};
</script>
