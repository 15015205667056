<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Add Teacher
          <div class="card-header-actions">
            <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <form>
              <div class="form-group row">
                <label
                  for="teacherNameFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Teacher Name</label
                >
                <div class="col-sm-8">
                  <input type="text" class="form-control" v-model="teacher">
                  
                </div>
              </div>
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="teacherNameFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Teacher Email</label
                >
                <div class="col-sm-8">
                  <input type="text" class="form-control" v-model="email">
                  
                </div>
              </div>
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="teacherNameFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Teacher Contact</label
                >
                <div class="col-sm-8">
                  <input type="text" class="form-control" v-model="contact">
                  
                </div>
              </div>
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >School</label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="school">
                    <option value disabled hidden>Select School</option>
                    <option
                        v-for="(y,i) in school_list"
                        v-bind:key="i"
                        :value="y.school_id"
                    >{{y.school_name}}</option>
                  </select>
                </div>
              </div>
              <!-- end form-group -->
              <!-- end form-group -->
              <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                  <CButton color="primary" size="md" class="border-0" @click="save_data1">
                    Submit
                  </CButton>&nbsp;&nbsp;
                  <!-- <button type="submit" class="btn btn-primary mr-3 text-white" @click="save_data1">
                    Submit
                  </button> -->
                  <CButton color="light" size="md" class="border-0" @click="reset">
                    Reset
                  </CButton>&nbsp;&nbsp;
                </div>
              </div>
            </form>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
      show: true,
      isCollapsed: true,
      teacher_list:[],
      class_list:[],
      division_list:[],
      subject_list:[],
      year_list:[],
      teacher:"",
      class_id:"",
      division:"",
      year:"",
      subject:"",
      contact:"",
      email:"",
      school:"",
      teacher:"",
      school_list:[]

    };
  },
  methods:{
    reset(){
      this.teacher="",
      this.email="";
      this.contact="";
      this.school="";
    },
    get_school(){
      var promise = apis.list_Schools().then( response => {
        this.school_list = response.data;
      }).catch( errror => {

      })
    },
    get_teacher(){
       var promise = apis.list_Teachers().then( response => {
          this.teacher_list = response.data;
      }).catch( errror => {

      })
    },
    get_class(){
       var promise = apis.list_ClassMasters().then( response => {
          this.class_list = response.data;
      }).catch( errror => {

      })
    },
    get_year(){
       var promise = apis.list_AcademicYears().then( response => {
          this.year_list = response.data;
      }).catch( errror => {

      })
    },
    get_subject(){
       var promise = apis.list_Subjects().then( response => {
          this.subject_list = response.data;
      }).catch( errror => {

      })
    },
    get_division(){
       var promise = apis.list_DivisionMasters().then( response => {
          this.division_list = response.data;
      }).catch( errror => {

      })
    },
    get_data(){
       var promise = apis.list_Teachers().then( response => {
          this.items = response.data;
         
      }).catch( errror => {

      })
    },
    save_data1(){
      var data = {
        teacher_name: this.teacher,
        teacher_email:this.email,
        contact_no:this.contact,
        school_id:this.school
        
      }
       var promise = apis.add_Teachers(data).then( response => {
          alert("Data Saved");
          this.reset();
          this.get_data();
          
      }).catch( errror => {

      })
    }
  },
  created(){
    this.get_subject();
    this.get_teacher();
    this.get_division();
    this.get_class();
    this.get_year();
    this.get_school();
    this.get_data();

  }
};
</script>
